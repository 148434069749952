import { useMemo, useContext } from 'react';
import { UserContext } from 'contexts/UserContext';

export const useUser = () => {
  const { user, setUser, logout, setPreviousUrl, previousUrl } =
    useContext(UserContext) || {};

  const roles = useMemo(() => user?.roles || [], [user]);

  const isContentAdmin = useMemo(
    () => user?.roles?.includes('4v-nexus-content-admin'),
    [user],
  );

  const isOntologyAdmin = useMemo(
    () => user?.roles?.includes('4v-nexus-ontology-admin'),
    [user],
  );

  const isAppAdmin = useMemo(
    () => user?.roles?.includes('4v-nexus-app-admin'),
    [user],
  );

  return {
    user,
    setUser,
    logout,
    setPreviousUrl,
    previousUrl,
    roles,
    isContentAdmin,
    isOntologyAdmin,
    isAppAdmin,
  };
};

export default useUser;

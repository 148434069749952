import { ConfigProvider as AntDesignProvider } from 'antd';

// The default #1677ff blue fails the WCAG AA standard (which is the one we're required to meet for SLAC).
// We pass a token to the theme to the aliased antd ConfigProvider in order to change #1660ff which meets the standard

const WCAGContrastRatioBlue = '#1660ff';
const WCAGContrastRatioRed = '#EA0000';

const NexusTheme = {
  token: {
    colorPrimary: WCAGContrastRatioBlue,
    colorInfo: WCAGContrastRatioBlue,
    colorLink: WCAGContrastRatioBlue,
    colorInfoText: WCAGContrastRatioBlue,
    colorPrimaryText: WCAGContrastRatioBlue,
    colorError: WCAGContrastRatioRed,
    colorErrorText: WCAGContrastRatioRed,
    colorHighlight: WCAGContrastRatioRed,
  },
};

export {
  NexusTheme,
  AntDesignProvider,
  WCAGContrastRatioBlue,
  WCAGContrastRatioRed,
};
